import React, { useEffect, useRef } from 'react';
import { CodeBlock, dracula } from 'react-code-blocks';
import { Card, CardContent, Typography, Box } from '@mui/material';

import DownloadContent from '../utils/DownloadContent';
import CustomTypeWriter from './CustomTypeWriter';
import useMobileView from '../utils/useMobileView';
import '../styles/loaderLine.css';

function Chat({
  currentTopic,
  message,
  isLoggedIn,
  onSuggestedTextClick,
  isLoading,
}) {
  const msgEnd = useRef(null);
  const { mobileView, tabletView } = useMobileView();

  useEffect(() => {
    // Use setTimeout to ensure content is rendered before scrolling
    const timer = setTimeout(() => {
      msgEnd.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 120);

    return () => clearTimeout(timer);
  }, [message, currentTopic]);

  useEffect(() => {
    // Use setTimeout to ensure content is rendered before scrolling
    const timer = setTimeout(() => {
      msgEnd.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 120);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full h-[80%] flex items-center justify-center overflow-hidden overflow-y-auto px-2 py-1">
      <div
        style={{
          position: 'absolute',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          opacity: 0.05,
          pointerEvents: 'none',
        }}
        className="rounded"
      />
      <div
        className={`w-full lg:w-4/5 flex flex-col h-full ${
          message?.length === 1 ? 'items-center' : 'items-start'
        } justify-start`}
      >
        {message.map((msg) => (
          <MessageWrapper
            key={msg.createdOn}
            link={msg.link}
            text={msg.text}
            prompt={msg.prompt}
            isBot={msg.isBot}
            isNewMessage={msg?.isNewMessage ?? false}
            mobileView={mobileView}
            tabletView={tabletView}
            type={msg.type}
          />
        ))}
        {isLoading && (
          <MessageWrapper
            isLoading={isLoading}
            key={new Date()}
            link={''}
            text={''}
            prompt={''}
            loader={<div class="loader-line"></div>}
            isBot={true}
            isNewMessage={false}
            mobileView={mobileView}
            tabletView={tabletView}
            type={'text'}
          />
        )}
        {/* Suggestion cards */}
        {message.length <= 1 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginTop: '16px',
            }}
          >
            {[
              {
                header: 'Ask Anything',
                prompt: 'Explain me how human evolution works.',
                emoji: '/chatImages/chat.png',
              },
              {
                header: 'Scan Contracts',
                prompt: 'Tell me about $SHIB token market data.',
                emoji: '/chatImages/ethereum.png',
              },
              {
                header: 'Generate Images',
                prompt: 'Generate Shiba Inu dog by Sakura tree.',
                emoji: '/chatImages/image.png',
              },
            ].map(({ header, emoji, prompt }) => {
              return (
                <Card
                  sx={{
                    margin: '16px 8px',
                    border: '1px solid #1f2937cc',
                    background: 'rgb(17 24 39 / 0.1)',
                    minWidth: mobileView ? 100 : 200,
                    cursor: isLoggedIn ? 'pointer' : 'auto',
                  }}
                  key={header}
                  onClick={() =>
                    onSuggestedTextClick ? onSuggestedTextClick(prompt) : null
                  }
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <img
                      width="48"
                      height="48"
                      src={emoji}
                      style={{ textAlign: 'center' }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#f5f5f5',
                        opacity: 0.7,
                        textAlign: 'center',
                      }}
                    >
                      {header}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        )}
        <div ref={msgEnd} />
      </div>
    </div>
  );
}

export default Chat;

const MessageWrapper = ({
  isBot,
  link,
  text,
  loader,
  prompt,
  type,
  isNewMessage = false,
  mobileView = false,
  tabletView = false,
  isLoading = false,
}) => {
  return (
    <>
      {/* Loader */}
      {loader && isLoading && (
        <span
          className={
            'flex items-start justify-start gap-2 lg:gap-5 my-2 bg-gray-800/50 p-3 rounded-md'
          }
          style={{ width: '80%' }}
        >
          <img
            src={'/icon.png'}
            alt="user"
            className="w-10 h-10 rounded object-cover"
          />
          <span className="rounded-md px-4 py-4">
            <div className="text-white text-[15px]">{loader}</div>
          </span>
        </span>
      )}

      {/* User Prompt */}
      {prompt?.length > 0 && (
        <span
          className={
            isBot
              ? 'flex items-start justify-center gap-2 lg:gap-5 my-2 bg-gray-800/80 p-3 rounded-md'
              : 'flex items-start justify-center gap-2 lg:gap-5 my-2 p-3'
          }
        >
          <img
            src={'/user.jpeg'}
            alt="user"
            className="w-10 h-10 rounded object-cover"
          />
          <span className="rounded-md bg-gray-600 px-4 py-4">
            <div
              className="text-white text-[15px]"
              style={{
                whiteSpace: 'pre-wrap',
                overflowX: 'hidden',
              }}
            >
              {renderText(prompt)}
            </div>
          </span>
        </span>
      )}
      {/* AI Response */}
      {(text?.length > 0 || link) && (
        <span
          className={
            isBot
              ? 'flex items-start justify-center gap-2 lg:gap-5 my-2 bg-gray-800/50 p-3 rounded-lg'
              : 'flex items-start justify-center gap-2 lg:gap-5 my-2 p-3 bg-gray-800/50 rounded-lg'
          }
        >
          <img
            src={'/icon.png'}
            alt="user"
            className="w-10 h-10 rounded object-cover"
          />
          {link ? (
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', right: 8, top: 8 }}>
                <DownloadContent contentName={prompt} contentUrl={link} />
              </div>
              <img
                src={link}
                alt="generated content"
                className="rounded object-cover"
                width={'500px'}
                height={'500px'}
                style={{ opacity: 1, zIndex: 1 }}
              />
              <p
                className="text-white text-[14px]"
                style={{ marginTop: '4px' }}
              >
                Here is the generated {type === 'meme' ? 'meme' : 'image'} for
                you.
              </p>
            </div>
          ) : (
            <div
              className="text-white text-[15px]"
              style={{
                whiteSpace: 'pre-wrap',
                overflow: 'auto',
                width: mobileView || tabletView ? '80vw' : '50vw',
              }}
            >
              <CustomTypeWriter
                parts={renderText(text)}
                isNewMessage={isNewMessage}
              />
            </div>
          )}
        </span>
      )}
    </>
  );
};

function renderText(text) {
  // Ensure the content is rendered only once by splitting at line breaks first
  const uniqueLines = new Set(text.split('\n')); // Using Set to avoid duplicates
  const processedText = Array.from(uniqueLines).join('\n');

  // Now proceed with the usual rendering flow
  const codeBlockRegex = /```([a-zA-Z]*)\n([\s\S]*?)```/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  // Handle code blocks first
  while ((match = codeBlockRegex.exec(processedText)) !== null) {
    const [fullMatch, language, code] = match;

    if (lastIndex < match.index) {
      const textBeforeCode = processedText.substring(lastIndex, match.index);
      parts.push(...splitTextWithInlineCode(textBeforeCode));
    }

    parts.push(
      <CodeBlock
        key={`code-block-${match.index}`}
        text={code.trim()}
        language={language}
        showLineNumbers={true}
        theme={dracula}
        wrapLongLines={false}
        codeContainerStyle={{ padding: '16px 0px' }}
        customStyle={{
          margin: '8px 0px',
          marginBottom: 0,
          width: '100%',
        }}
      />,
    );

    lastIndex = codeBlockRegex.lastIndex;
  }

  if (lastIndex < processedText.length) {
    const remainingText = processedText.substring(lastIndex);
    parts.push(...splitTextWithInlineCode(remainingText));
  }

  return parts;
}

function splitTextWithInlineCode(text) {
  const inlineCodeRegex = /`([^`]+)`/g;

  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = inlineCodeRegex.exec(text)) !== null) {
    if (lastIndex < match.index) {
      const textBeforeCode = text.substring(lastIndex, match.index);
      parts.push(...splitTextWithBoldAndLinks(textBeforeCode));
    }

    const codeText = match[1];
    parts.push(
      <code
        key={`inline-code-${match.index}`}
        style={{
          backgroundColor: 'gray',
          padding: '2px 4px',
          borderRadius: '4px',
        }}
      >
        {codeText}
      </code>,
    );

    lastIndex = inlineCodeRegex.lastIndex;
  }

  if (lastIndex < text.length) {
    const remainingText = text.substring(lastIndex);
    parts.push(...splitTextWithBoldAndLinks(remainingText));
  }

  return parts;
}

function splitTextWithBoldAndLinks(text) {
  const boldTextRegex = /\*\*(.*?)\*\*/g;

  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = boldTextRegex.exec(text)) !== null) {
    if (lastIndex < match.index) {
      const textBeforeBold = text.substring(lastIndex, match.index);
      parts.push(...splitTextWithLinks(textBeforeBold));
    }

    const boldText = match[1];
    parts.push(
      <strong key={`bold-${match.index}`} style={{ fontWeight: 'bold' }}>
        {boldText}
      </strong>,
    );

    lastIndex = boldTextRegex.lastIndex;
  }

  if (lastIndex < text.length) {
    const remainingText = text.substring(lastIndex);
    parts.push(...splitTextWithLinks(remainingText));
  }

  return parts;
}

function splitTextWithLinks(text) {
  const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;

  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = linkRegex.exec(text)) !== null) {
    if (lastIndex < match.index) {
      const textBeforeLink = text.substring(lastIndex, match.index);
      parts.push(textBeforeLink);
    }

    const linkText = match[1];
    const url = match[2];
    parts.push(
      <a
        key={`link-${match.index}`}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#1E90FF' }}
      >
        {linkText}
      </a>,
    );

    lastIndex = linkRegex.lastIndex;
  }

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return parts;
}
