import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';

const NavInputDialog = ({
  open,
  onClose,
  onChange,
  value,
  dialogTitle,
  handleAddNewFolder,
  isEditing,
  onSave,
  editedTitle,
  setEditedTitle,
}) => {
  const handleSaveEdit = () => {
    onSave();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          width: 500,
          maxWidth: '90%',
          backgroundColor: '#374151',
          color: '#FFFF',
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: '1.3rem',
          fontWeight: 'bold',
          padding: '16px 24px',
          color: '#F3F4F6',
        }}
      >
        {isEditing ? dialogTitle : 'Add New Folder!'}
      </DialogTitle>
      <DialogContent>
        {isEditing ? (
          <TextField
            type="text"
            variant="outlined"
            placeholder="New File Name"
            multiline={true}
            autoFocus
            fullWidth
            size="small"
            fullwidth
            value={editedTitle}
            onChange={(e) => {
              setEditedTitle(e.target.value);
            }}
            sx={{
              textarea: {
                color: '#FFFF',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#FFFF',
                },
                '&:hover fieldset': {
                  borderColor: '#FFFF',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FFFF',
                },
              },
            }}
            style={{ minHeight: '40px', maxHeight: 160 }}
          />
        ) : (
          <TextField
            type="text"
            variant="outlined"
            placeholder="Folder Name"
            multiline={true}
            autoFocus
            fullWidth
            size="small"
            value={value}
            onChange={onChange}
            sx={{
              textarea: {
                color: '#FFFF',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#FFFF',
                },
                '&:hover fieldset': {
                  borderColor: '#FFFF',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FFFF',
                },
              },
            }}
            style={{ minHeight: '40px', maxHeight: 160 }}
          />
        )}
      </DialogContent>

      {isEditing ? (
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            sx={{ color: '#FFFF', textTransform: 'none' }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            variant="contained"
            size="small"
            onClick={() => {
              handleSaveEdit();
            }}
          >
            Update
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            sx={{ color: '#FFFF', textTransform: 'none' }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            size="small"
            variant="contained"
            onClick={handleAddNewFolder}
          >
            Add
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default NavInputDialog;

export const DeleteDialog = ({ open, onClose, onDelete, deleteTitle }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          width: 500,
          maxWidth: '90%',
          backgroundColor: '#374151',
          color: '#FFFF',
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: '1.3rem',
          fontWeight: 'bold',
          padding: '16px 24px',
          color: '#F3F4F6',
        }}
      >
        {deleteTitle}
      </DialogTitle>
      <hr />
      <DialogContent>
        <Typography>
          Deleting this data will permanently remove it from your system and may
          not be recoverable. Are you sure you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          sx={{ color: '#FFFF', textTransform: 'none' }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          size="small"
          variant="contained"
          color="error"
          onClick={onDelete}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
