import React from 'react';
import ReactDOM from 'react-dom/client';
import AppContext from './utils/Context';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import reportWebVitals from './reportWebVitals';

import Home from './Page/Home';
import './index.css';

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '';

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
};

const metadata = {
  name: 'zkGPT DApp',
  description: 'zkGPT DApp',
  url: '',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};
// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,
  auth: {
    email: false,
    socials: [],
    showWallets: true,
    walletFeatures: false,
  },

  /* Optional */
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
});

// 5. Create a AppKit instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: false,
});
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppContext>
        <Home />
      </AppContext>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
