import { TwitterIconSVG } from '../components/svg/twitterIconSvg';
import { NoteIconSVG } from '../components/svg/noteIconSvg';
import { WebsiteIconSVG } from '../components/svg/websiteIconSvg';

const Footer = () => {
  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'flex-end',
        position: 'fixed',
        right: '12px',
        bottom: 12,
      }}
    >
      <a href="https://t.me/xxx" target="_blank">
        <img
          width="24"
          height="24"
          src="https://img.icons8.com/color/230/telegram-app--v1.png"
          alt="telegram-app--v1"
          style={{ marginRight: '2px' }}
        />
      </a>
      <a href="https://x.com/xxx" target="_blank">
        <TwitterIconSVG width="24" height="24" style={{ marginRight: '2px' }} />
      </a>
      <a href="https://xxx.gitbook.io/" target="_blank">
        <NoteIconSVG width="22" height="22" style={{ marginRight: '2px' }} />
      </a>
    </div>
  );
};

export default Footer;
