import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ContextApp } from '../utils/Context';
import { TextField } from '@mui/material';
import { LuPanelLeftOpen } from 'react-icons/lu';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { fetchAllActivitiesByTopicId } from '../utils/API';
import Chat from './Chat';
import { createActivityInTopic } from '../utils/API';
import WalletConnect from './WalletConnect';

import useMobileView from '../utils/useMobileView';

import CurrencyStats from '../components/CurrencyStats';
import '../styles/loader.css';

const defaultMessageObject = {
  topicId: '1',
  text: `Hey there, I'm zkGPT, the AI with an edge, crafted by zkGPT Dev. I'm here for your wildest queries, from the cosmos to the crypto. Got a question? Let's dive in and see what chaos we can cook up together!.`,
  isBot: true,
  isNewMessage: false,
};

function ChatContainer() {
  const {
    setShowSlide,
    showSlide,
    setMobile,
    Mobile,
    chatValue,
    setChatValue,
    isLoggedIn,
    setRefetchTopics,
  } = useContext(ContextApp);
  const { mobileView, tabletView } = useMobileView();
  const [message, setMessage] = useState([defaultMessageObject]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { topicId } = params || {};

  const { data: activitiesObj = {}, refetch: fetchActivities } = useQuery(
    'fetchAllActivitiesByTopicId',
    () => fetchAllActivitiesByTopicId(topicId),
    {
      enabled: !!topicId && isLoggedIn,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  const activities = activitiesObj?.activities ?? [];

  useEffect(() => {
    if (activities.length && topicId) {
      setMessage(activities);
    } else if (topicId && !activities.length) {
      setMessage([defaultMessageObject]);
    }
  }, [activities, topicId]);

  useEffect(() => {
    if (topicId && isLoggedIn) {
      fetchActivities();
    } else {
      setMessage([defaultMessageObject]);
      // navigate('/');
    }
  }, [topicId, isLoggedIn]);

  const handleSend = async (customMessage = '') => {
    const text = chatValue?.trim() || customMessage?.trim();
    if (!text) {
      return;
    }
    setIsLoading(true);
    setChatValue('');
    setMessage([...message, { prompt: text, isBot: false, topicId }]);

    const activityRes = await createActivityInTopic({
      topicId,
      prompt: text,
    });
    const activity = (activityRes?.activities ?? [])[0];
    if (!activity) {
      setIsLoading(false);
      return;
    }
    setMessage([
      ...message,
      { prompt: text, isBot: false, topicId },
      { ...activity, prompt: '', isBot: true, isNewMessage: true },
    ]);
    setIsLoading(false);

    // Refetch topic to update the topic name
    // On first message
    if (activities.length === 0 && message.length <= 1) {
      setRefetchTopics(true);
    }
  };

  const handleKeyPress = () => {
    handleSend();
  };

  return (
    <div
      className={
        showSlide
          ? 'h-[100dvh] w-screen bg-gray-700 flex items-start justify-between flex-col p-2'
          : 'h-[100dvh] w-full lg:w-[calc(100%-300px)] bg-gray-700 flex items-start justify-between flex-col p-2'
      }
    >
      <div>
        {!mobileView && <CurrencyStats showSlide={showSlide} />}
        <span
          className="rounded px-3 py-[11px] hidden lg:flex items-center justify-center cursor-pointer text-white bg-gray-600 duration-200"
          title="Open sidebar"
          style={{
            position: 'absolute',
            top: 7,
            zIndex: 2,
            marginTop: 0,
            display: !showSlide ? 'none' : 'block',
          }}
          onClick={() => setShowSlide(!showSlide)}
        >
          {showSlide && <LuPanelLeftOpen />}
        </span>
        <span
          className="rounded px-3 py-[9px] lg:hidden flex items-center justify-center cursor-pointer text-white mt-0 mb-3 border border-gray-600"
          title="Open sidebar"
          onClick={() => setMobile(!Mobile)}
        >
          <HiOutlineMenuAlt2 fontSize={20} />
        </span>
      </div>
      {/* chat section */}
      <Chat
        message={message}
        currentTopic={topicId}
        onSuggestedTextClick={(message) => {
          if (isLoggedIn) {
            handleSend(message);
          }
        }}
        isLoggedIn={isLoggedIn}
        isLoading={isLoading}
      />
      {/* chat input section */}
      <div className=" w-full  m-auto flex items-center justify-center flex-col gap-2 my-2">
        <span className="flex gap-2 items-center justify-center bg-gray-600 rounded-lg shadow-md w-[90%] lg:w-2/5 xl:w-1/2">
          <TextField
            type="text"
            multiline={true}
            autoFocus
            fullWidth
            size="small"
            maxRows={chatValue.length < 30 ? 1 : 4}
            sx={{
              '& fieldset': { border: 'none' },
              textarea: {
                color: '#eee',
              },
            }}
            placeholder={
              isLoggedIn ? 'Send a message' : 'Please connect a wallet.'
            }
            style={{ minHeight: '40px', maxHeight: 160 }}
            value={chatValue}
            onChange={(e) => {
              setChatValue(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' && e.shiftKey === false) {
                setIsLoading(true);
                handleKeyPress();
              }
            }}
            disabled={!isLoggedIn || isLoading}
          />
          {isLoggedIn && !isLoading && (
            <RiSendPlane2Fill
              title="send message"
              className={
                chatValue.length <= 0
                  ? 'text-gray-400 cursor-auto mx-3 text-xl'
                  : 'text-white cursor-pointer mx-3 text-3xl bg-green-500 p-1 rounded shadow-md '
              }
              onClick={handleSend}
            />
          )}
          {isLoggedIn && isLoading && <div class="loader"></div>}
        </span>
        {mobileView && !isLoggedIn && (
          <div className="w-full border-t border-gray-600 flex flex-col gap-2 items-center justify-center p-2">
            <WalletConnect />
          </div>
        )}
        <p className="lg:text-xs text-gray-400 text-left text-[10px]">
          Build for Web3, powered by{' '}
          <a
            href="https://token.zkgpt.io"
            target="_blank"
            style={{ color: '#2196f3' }}
          >
            <u>zkGPT</u>
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default ChatContainer;
