export const TwitterIconSVG = ({
  height,
  width,
  color = '#aaa',
  style = {},
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      style={style}
    >
      <path
        d="M20,6v12c0,1.1-0.9,2-2,2H6c-1.11,0-2-0.9-2-2V6c0-1.11,0.89-2,2-2h12C19.1,4,20,4.89,20,6z"
        opacity=".3"
      ></path>
      <path
        fill={color}
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        d="M18,20	H6c-1.105,0-2-0.895-2-2V6c0-1.105,0.895-2,2-2h12c1.105,0,2,0.895,2,2v12C20,19.105,19.105,20,18,20z"
      ></path>
      <path
        fill-rule="evenodd"
        d="M13.351,11.01L16.774,7h-1.311l-2.704,3.16L10.552,7h-3.85l3.941,5.633	L6.906,17h1.333l3.001-3.516L13.698,17h3.839L13.351,11.01z M8.566,7.988h1.487l5.631,8.024h-1.497L8.566,7.988z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};
