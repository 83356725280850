export const NoteIconSVG = ({ height, width, color = '#aaa', style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 6.5859375 L 15.414062 2 L 6 2 z M 6 4 L 14 4 L 14 8 L 18 8 L 18 20 L 6 20 L 6 4 z M 8 10 L 8 12 L 16 12 L 16 10 L 8 10 z M 8 13 L 8 15 L 16 15 L 16 13 L 8 13 z M 8 16 L 8 18 L 13 18 L 13 16 L 8 16 z"
      ></path>
    </svg>
  );
};
