import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, Avatar } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getOverallMarketStats } from '../utils/API';
import '../styles/marquee.css';

const currencies = [
  {
    name: 'Bitcoin',
    symbol: 'BTC',
    id: 'bitcoin',
    link: 'https://www.coingecko.com/en/coins/bitcoin',
    logo: 'https://assets.coingecko.com/coins/images/1/standard/bitcoin.png?1696501400',
  },
  {
    name: 'Ethereum',
    symbol: 'ETH',
    id: 'ethereum',
    link: 'https://www.coingecko.com/en/coins/ethereum',
    logo: '/eth.png',
  },
  {
    name: 'Binance Coin',
    symbol: 'BNB',
    id: 'binancecoin',
    link: 'https://www.coingecko.com/en/coins/bnb',
    logo: 'https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970',
  },
  {
    name: 'Avalanche',
    symbol: 'AVAX',
    id: 'avalanche-2',
    link: 'https://www.coingecko.com/en/coins/avalanche',
    logo: 'https://assets.coingecko.com/coins/images/12559/standard/Avalanche_Circle_RedWhite_Trans.png?1696512369',
  },
  {
    name: 'Solana',
    symbol: 'SOL',
    id: 'solana',
    link: 'https://www.coingecko.com/en/coins/solana',
    logo: 'https://assets.coingecko.com/coins/images/4128/standard/solana.png?1718769756',
  },
  {
    name: 'Ripple',
    symbol: 'XRP',
    id: 'ripple',
    link: 'https://www.coingecko.com/en/coins/xrp',
    logo: 'https://assets.coingecko.com/coins/images/44/standard/xrp-symbol-white-128.png?1696501442',
  },
  {
    name: 'Dogecoin',
    symbol: 'DOGE',
    id: 'dogecoin',
    link: 'https://www.coingecko.com/en/coins/dogecoin',
    logo: 'https://assets.coingecko.com/coins/images/5/standard/dogecoin.png?1696501409',
  },
  {
    name: 'Shiba Inu',
    symbol: 'SHIB',
    id: 'shiba-inu',
    link: 'https://www.coingecko.com/en/coins/shiba-inu',
    logo: 'https://assets.coingecko.com/coins/images/11939/standard/shiba.png?1696511800',
  },
  {
    name: 'Toncoin',
    symbol: 'TON',
    id: 'the-open-network',
    link: 'https://www.coingecko.com/en/coins/toncoin',
    logo: 'https://assets.coingecko.com/coins/images/17980/standard/img-ton.jpg?1724681212',
  },
];

const colorRed = '#ff3d00';
const colorGreen = '#4caf50';

function CurrencyStats({ showSlide = false }) {
  const { data: currencyStats = {} } = useQuery(
    'getOverallMarketStats',
    () => getOverallMarketStats(),
    {
      enabled: true,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const currencyData = useMemo(() => {
    if (!isEmpty(currencyStats)) {
      return currencies.map((each) => {
        return {
          ...each,
          ...currencyStats[each.id],
        };
      });
    }
    return [];
  }, [currencyStats]);

  return (
    <Box
      style={{
        margin: '0px auto',
        marginBottom: '0px',
        width: '100%',
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 0,
        top: '8px',
      }}
    >
      <StyledMarquee
        gradient={false}
        speed={20}
        play={true}
        pauseOnClick
        pauseOnHover
      >
        <Stack direction="row" spacing={1}>
          {currencyData.map(
            ({ logo, link, id, name, symbol, usd, usd_24h_change }) => {
              return (
                <Link key={id} to={link} target="_blank">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#FFF',
                      background: 'rgb(17,24,39)',
                      padding: '0px 8px',
                    }}
                  >
                    <div>
                      <Avatar
                        alt={symbol}
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: '#eee',
                          padding: '2px',
                        }}
                        src={logo}
                      />
                    </div>
                    <div style={{ margin: '0px 0px 0px 8px' }}>
                      <Typography fontSize="12px" display="flex">
                        <b>{name}</b>{' '}
                        <span style={{ color: '#eee', marginLeft: '8px' }}>
                          {symbol}
                        </span>
                      </Typography>
                      <Typography
                        fontSize="12px"
                        fontWeight={'700'}
                        display="flex"
                      >
                        <b>${usd}</b>
                        <span
                          style={{
                            marginLeft: '4px',
                            color: usd_24h_change > 0 ? colorGreen : colorRed,
                          }}
                        >
                          {usd_24h_change > 0 ? (
                            <ArrowUpwardIcon
                              fontSize="small"
                              sx={{ padding: '2px', marginTop: '-2px' }}
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="small"
                              sx={{ padding: '2px', marginTop: '-2px' }}
                            />
                          )}
                          {usd_24h_change?.toFixed(2)}%
                        </span>
                      </Typography>
                    </div>
                  </div>
                </Link>
              );
            },
          )}
        </Stack>
      </StyledMarquee>
    </Box>
  );
}

export default CurrencyStats;

const StyledMarquee = styled(Marquee)`
  div.marquee {
    --duration: 40.5667s !important;
  }
`;
