import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LeftNav from '../components/LeftNav';
import ChatContainer from '../components/ChatContainer';
import Mobile from '../components/Mobile';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div className="flex w-screen relative overflow-hidden">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <LeftNav />
                <ChatContainer />
                <span className="flex lg:hidden">
                  <Mobile />
                </span>
                <Footer />
              </>
            }
          />
          <Route
            path="/chat/:topicId"
            element={
              <>
                <LeftNav />
                <ChatContainer />
                <span className="flex lg:hidden">
                  <Mobile />
                </span>
                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default Home;
