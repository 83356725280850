import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { truncate } from 'lodash';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiTrash, FiEdit } from 'react-icons/fi';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Tooltip } from '@mui/material';

import { MdClose } from 'react-icons/md';
import { ContextApp } from '../utils/Context';
import WalletConnect from './WalletConnect';
import FolderWrapper from './FolderWrapper';
import { getFormattedCurrentDate } from '../utils';

import NavInputDialog, { DeleteDialog } from './NavDialog';

import {
  fetchAllFoldersByWallet,
  fetchAllTopicsByFolderId,
  createNewTopicInFolder,
  createNewFolder,
  updateTopic,
  deleteTopicById,
} from '../utils/API';

function Mobile() {
  const {
    isLoggedIn,
    currentTopic,
    setCurrentTopic,
    refetchTopics,
    setRefetchTopics,
    currentWallet,
    Mobile,
    setMobile,
  } = useContext(ContextApp);
  const leftNavbarTop = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const { topicId } = params || {};

  const [openDialog, setopenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [hoveredTopicId, setHoveredTopicId] = useState(null);
  const [newFolder, setNewFolder] = useState('');
  const [selectedFolderId, setSelectedFolderId] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editTopicId, setEditTopicId] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [topicIdToDelete, setTopicIdToDelete] = useState(null);
  const { data = {}, refetch: refetchfolders } = useQuery(
    'fetchAllFoldersByWallet',
    fetchAllFoldersByWallet,
    {
      enabled: isLoggedIn,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const folders = data?.folders ?? [];

  const { data: topicsObj = {}, refetch: refetchTopicsForFolder } = useQuery(
    ['fetchAllTopicsByFolderId', selectedFolderId],
    () =>
      selectedFolderId
        ? fetchAllTopicsByFolderId(selectedFolderId)
        : { topic: [] },
    {
      enabled: isLoggedIn && folders.length > 0,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const topics = topicsObj?.topics ?? [];

  useEffect(() => {
    if (!selectedFolderId && folders.length) {
      setSelectedFolderId(folders[0].id);
    }
  }, [folders, selectedFolderId]);

  useEffect(() => {
    if (!topicId && topics.length) {
      navigate(`/chat/${topics[0].id}`);
    }
  }, [currentTopic, topics, topicId]);

  useEffect(() => {
    if (refetchTopics) {
      refetchTopicsForFolder(selectedFolderId);
      setRefetchTopics(false);
    }
  }, [refetchTopics]);

  useEffect(() => {
    if (currentWallet) {
      refetchfolders();
    }
  }, [currentWallet]);

  const handleCreateNewTopic = async (folderId) => {
    try {
      const res = await createNewTopicInFolder({
        folderId,
        title: `Topic - ${topics.length + 1}-${getFormattedCurrentDate()}`,
      });
      await refetchTopicsForFolder(folderId);
      const topicsArray = res?.topics ?? [];
      if (!topicsArray.length) {
        return;
      }
      leftNavbarTop.current.scrollIntoView();
      const newTopic = topicsArray[0];
      if (newTopic?.id) {
        setCurrentTopic(newTopic.id);
        navigate(`/chat/${newTopic.id}`);
      }
    } catch (e) {
      console.error('Error creating new topic:', e);
    }
  };

  const handleTopicEdit = (e, topic) => {
    e.preventDefault();
    e.stopPropagation();
    setEditTopicId(topic.id);
    setEditedTitle(topic.title);
    setIsEditing(true);
    setOpenEditDialog(true);
    setDeleteDialogOpen(false);
  };

  const handleSaveEdit = async () => {
    if (!editedTitle.trim()) {
      console.error('Title cannot be empty.');
      return;
    }
    try {
      const updatedData = editedTitle;
      const topicId = editTopicId;
      await updateTopic({ topicId, title: updatedData });
      refetchTopicsForFolder(selectedFolderId);
      setEditTopicId(null);
      setIsEditing(false);
      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error saving edited topic:', error);
    }
  };

  const handleTopicDelete = (e, topicId) => {
    e.preventDefault();
    e.stopPropagation();
    setTopicIdToDelete(topicId);
    setDeleteDialogOpen(true);
    setCurrentTopic(null);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const confirmDeleteTopic = async () => {
    try {
      await deleteTopicById({ topicIdToDelete });
      const updatedTopicsObj = await refetchTopicsForFolder(selectedFolderId);
      const updatedTopics = updatedTopicsObj?.data?.topics || [];
      setDeleteDialogOpen(false);
      setTopicIdToDelete(null);
      if (updatedTopics.length > 0) {
        navigate(`/chat/${updatedTopics[0].id}`);
      } else {
        navigate(`/chat/${selectedFolderId}`);
      }
    } catch (error) {
      console.error('Error deleting topic:', error);
    }
  };

  const handleAddNewFolder = async () => {
    try {
      if (!newFolder.trim()) {
        console.error('Folder name cannot be empty.');
        return;
      }
      await createNewFolder({
        wallet: localStorage.getItem('wallet'),
        title: newFolder,
      });
      refetchfolders();
      setNewFolder('');
      handleCloseDialog();
    } catch (error) {
      console.error('Error creating new folder:', error);
    }
  };

  const handleChangeFolderName = (event) => {
    setNewFolder(event.target.value);
  };

  const handleopenDialog = () => {
    setopenDialog(true);
  };

  const handleCloseDialog = () => {
    setopenDialog(false);
    setNewFolder('');
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setIsEditing(false);
  };

  const handleFolderSelect = (folderId) => {
    setSelectedFolderId(folderId);
    refetchTopicsForFolder();
  };
  return (
    <div className="absolute left-0 top-0 w-full z-50  bg-black/40 flex justify-between items-start">
      <div
        className={
          Mobile
            ? 'h-[100dvh] bg-gray-900 w-[300px]  flex items-center justify-between p-2 text-white flex-col translate-x-0'
            : 'hidden'
        }
      >
        <div className="flex items-start justify-between w-full">
          <span
            className="border border-gray-600  rounded w-full py-2 text-xs flex gap-1 items-center justify-center cursor-pointer "
            onClick={handleopenDialog}
          >
            <AiOutlinePlus fontSize={18} />
            New folder
          </span>
          {isLoggedIn && (
            <NavInputDialog
              open={openDialog}
              onClose={handleCloseDialog}
              value={newFolder}
              onChange={handleChangeFolderName}
              handleAddNewFolder={handleAddNewFolder}
            />
          )}
        </div>
        {/* middle section  */}
        <div className="h-[75%] w-full p-0 overflow-hidden overflow-y-auto text-sm scroll my-2">
          {/* msg  */}
          <div ref={leftNavbarTop}>
            {isLoggedIn && (
              <FolderWrapper
                handleNewTopic={(folderId) =>
                  isLoggedIn && handleCreateNewTopic(folderId)
                }
                folders={folders}
                refetchTopicsForFolder={handleFolderSelect}
                defaultExpandedFolder={selectedFolderId}
              >
                {topics.map((topic) => (
                  <Link
                    key={topic.id}
                    to={`/chat/${topic.id}`}
                    onClick={() => {
                      if (hoveredTopicId !== topic.id) {
                        setHoveredTopicId(topic.id);
                      }
                    }}
                  >
                    <TooltipContent
                      topic={topic}
                      currentPageTopicId={topicId}
                      onEditTopic={handleTopicEdit}
                      onDeleteTopic={handleTopicDelete}
                      hoveredTopicId={hoveredTopicId}
                      editTopicId={editTopicId}
                      setEditTopicId={setEditTopicId}
                      setMobile={setMobile}
                      editedTitle={editedTitle}
                      setEditedTitle={setEditedTitle}
                      handleSaveEdit={handleSaveEdit}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      openDialog={openDialog}
                      openEditDialog={openEditDialog}
                      onCloseTooltip={() => {
                        setHoveredTopicId(null);
                      }}
                    />
                  </Link>
                ))}
              </FolderWrapper>
            )}
            {isEditing && (
              <NavInputDialog
                open={openEditDialog}
                onClose={handleCloseEditDialog}
                isEditing={true}
                editedTitle={editedTitle}
                setEditedTitle={setEditedTitle}
                disableBackdropClick={true}
                PaperProps={{
                  style: {
                    zIndex: 1300,
                  },
                }}
                BackdropProps={{
                  style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  },
                }}
                onCancel={() => {
                  setEditTopicId(null);
                  setIsEditing(false);
                }}
                onSave={() => {
                  handleSaveEdit();
                }}
              />
            )}
            <DeleteDialog
              open={deleteDialogOpen}
              onClose={handleCloseDeleteDialog}
              onDelete={() => confirmDeleteTopic()}
            />
          </div>
        </div>
        {/* bottom section  */}
        <div className="w-full border-t border-gray-600 flex flex-col gap-2 items-center justify-center p-2">
          <span
            className="rounded w-full py-2 px-2 text-xs flex gap-1 items-center justify-between cursor-pointer hover:bg-gray-800 transition-all duration-300"
            style={{
              border: '2px solid rgb(70, 70, 70)',
            }}
          >
            <span className="flex gap-1 items-center justify-center text-sm">
              <img
                src={'/eth.png'}
                alt="user"
                className="w-6 h-6 rounded object-cover"
              />
              Join zkGPT
            </span>
            <span className="rounded-md bg-yellow-200 px-1.5 py-0.5 text-xs font-medium uppercase text-gray-800">
              Ad
            </span>
          </span>
          <WalletConnect />
        </div>
      </div>
      {Mobile && (
        <span
          className="border bg-gray-600 text-white m-2 rounded px-3 py-[9px] flex items-center justify-center cursor-pointer"
          title="Close sidebar"
          onClick={() => {
            setMobile(!Mobile);
          }}
        >
          <MdClose />
        </span>
      )}
    </div>
  );
}

export default Mobile;

const TooltipContent = ({
  topic,
  currentPageTopicId,
  onEditTopic,
  onDeleteTopic,
  isEditing,
  openDialog,
  openEditDialog,
  setMobile,
}) => {
  const [currentTopicSettings, setCurrentTopicSettings] = React.useState(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setCurrentTopicSettings(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <Tooltip
      open={currentTopicSettings === topic.id && !openDialog && !openEditDialog}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      sx={{
        '.MuiTooltip-tooltip': {
          border: '1px solid #111827',
          padding: '8px',
          backgroundColor: '#1F2937',
        },
        '.MuiTooltip-arrow': {
          color: '#1F2937',
        },
      }}
      placement="bottom"
      arrow
      title={
        <div
          className="bg-gray-900 p-2 rounded shadow-md"
          style={{
            minWidth: '180px',
            padding: '16px',
          }}
          ref={tooltipRef}
        >
          <div
            className={`rounded w-full py-2 px-2 text-xs my-2 flex gap-1 items-center cursor-pointer hover:bg-gray-800 transition-all duration-300 overflow-hidden truncate whitespace-nowrap bg-gray-700`}
            onClick={(e) => {
              onEditTopic(e, topic);
              setCurrentTopicSettings(null);
            }}
          >
            <FiEdit />
            <span className="text-sm">Edit</span>
          </div>
          <div
            className={`rounded w-full py-2 px-2 text-xs my-2 flex gap-1 items-center cursor-pointer hover:bg-gray-800 transition-all duration-300 overflow-hidden truncate whitespace-nowrap bg-gray-700`}
            onClick={(e) => {
              onDeleteTopic(e, topic.id);
              setCurrentTopicSettings(null);
            }}
          >
            <FiTrash />
            <span className="text-sm">Delete</span>
          </div>
        </div>
      }
    >
      <span
        className={`rounded w-full py-2 px-2 text-xs my-1 flex gap-1 items-center justify-between cursor-pointer hover:bg-gray-800 transition-all duration-300 overflow-hidden truncate whitespace-nowrap ${
          topic.id === currentPageTopicId ? 'bg-gray-700' : ''
        }`}
        onClick={() => {
          setMobile(!Mobile);
        }}
        value={topic.title}
        style={{ position: 'relative' }}
      >
        <span className="flex w-full gap-2 text-base">
          <img
            width="22"
            height="22"
            src={
              topic.id === currentPageTopicId
                ? '/chatImages/chat.png'
                : '/chatImages/chat-gray.png'
            }
            style={{ marginLeft: -4, padding: 0 }}
          />
          <span className="text-sm">
            {truncate(topic.title, {
              length: 27,
            })}
          </span>
        </span>
        {topic.id === currentPageTopicId && !isEditing && (
          <span
            style={{
              position: 'absolute',
              paddingLeft: 2,
              right: '0px',
            }}
            className="flex items-center"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setCurrentTopicSettings(topic.id);
            }}
          >
            <MoreHorizIcon />
          </span>
        )}
      </span>
    </Tooltip>
  );
};
